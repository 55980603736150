<template>
    <!-- 
        Сторінка "Статистика з відділу розробки"
        - дозволяє переглянути список співробітників та інформацію по тікетам та проектам ( з пошуком по імені), та з фільтрацією по співробітнику та періоду
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <!-- кнопка відображення інфо-діаграми -->
        <div class="hstack gap-2">
            <button 
                v-if="this.checks['001'] == true" 
                @click="chartblockShow == true ? closechartblock() : chartblock()" 
                type="button" 
                :class="`btn btn-${chartblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
            >
                <i :class="`${chartblockShow == true ? 'bx bxs-hide' : 'ri-pie-chart-fill'}`"></i>
            </button>
        </div>
    </div>

    <!-- 
        Діаграма по розробникам по - 
        - тікетам
        - проектам
    -->
    <circular 
        v-if="chartblockShow" 
        :title="$t('Employees')" 
        :obj="this.chartall" 
        style="margin-top:15px;" 
    />
    
    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter"
    />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import circular from '@/components/statistics/circular' // компонент діаграми
import { Statistics } from '@/API' // клас для взаємодії з API 
import { storeS } from "@/store";  // глобальне сховище даних

let apiServe = new Statistics();

export default {
    components: {
        PageHeader,
        tablecustom,
        circular
    },
    data(){
        return{
            title: this.$t('develStatistics'), // Заголовок
            chartblockShow: false, // прапорець відображення діаграми
            objPages: {},  // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "statsByDevs"
            },
            objParams:{ // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerId: '',
                pr1: '',
                pr2: ''
            },
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: 'ID',
                    text: "workerId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('name'),
                    text: "workerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('Position'),
                    text: "workerJob",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t('ticketov') + " (" + this.$t('statusInWork') + ")",
                    text: "inJobTicketsCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('completedTickets'),
                    text: "decidedTicketsCount",
                    align: "left",
                    status: true,
                },
                // {
                //     name: this.$t('completedTickets'),
                //     text: "closedTicketsCount",
                //     align: "left",
                //     status: true,
                // },
                {
                    name: this.$t('efficiency') + " (" + this.$t('tickets') + ")",
                    text: "efficiencyByTickets",
                    align: "left",
                    status: true,
                    mutate: (item) => item.toFixed(2) + " %"
                },
                {
                    name: this.$t('projects') + " (" + this.$t('statusInWork') + ")",
                    text: "inJobProjectsCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('completedProjects'),
                    text: "decidedProjectsCount",
                    align: "left",
                    status: true,
                },
                // {
                //     name: this.$t('completedProjects'),
                //     text: "closedProjectsCount",
                //     align: "left",
                //     status: true,
                // },
                {
                    name: this.$t('efficiency') + " (" + this.$t('navProjects') + ")",
                    text: "efficiencyByProjects",
                    align: "left",
                    status: true,
                    mutate: (item) => item.toFixed(2) + " %"
                }
            ],
            rows: [],  // Дані для відображення у таблиці
            chartall: [] // Дані для діаграми
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання статистики з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список співробітників) та objPages (інформація про сторінки).
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.getStatAlldev(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #503`);
                }
            })
        },
        chartblock(){
            /**
                * Підготовка даних для відображення в блоку графіків.
                * Додавання імені працівника, кількості тікетів та проектів у відповідне поле об'єкта.
            */

            for(var item in this.rows){
                var value = {
                    name: this.rows[item].workerName,
                    count: this.rows[item].inJobTicketsCount,
                    items: [
                        {
                            category: "ticketov",
                            change: '0',
                            value: this.rows[item].inJobTicketsCount
                        },
                        {
                            category: "projects",
                            change: '1',
                            value: this.rows[item].inJobProjectsCount
                        }
                    ]
                }
                this.chartall.push(value)
            }
            this.chartblockShow = true
        },
        closechartblock(){
            /*
                Закриття блоку графіків та очищення даних.
            */

            this.chartall = []
            this.chartblockShow = false
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за іменем співробітника
            */

            this.objParams.search = e
            this.getdata();
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
    },
    computed: {
        checks() {
            /*
                Отримуємо дані налаштувань з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>